import {
  Autocomplete,
  AutocompleteChangeReason,
  TextField,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import { OrderLineModel, ProductModel } from "../../Utils/Models";
import { getData } from "../../Services/getData";
import { Controller } from "react-hook-form";
import { EditProductProps } from "../../Utils/Types";
import { AxiosResponse } from "axios";
import useCustomMemo from "../../Hooks/useCustomMemo";
import styles from "../../Styles/products.module.css";
import { Box } from "@mui/system";

export const EditProduct = (props: EditProductProps) => {
  const [products, setProducts] = useState([] as ProductModel[]);
  const [localError, setLocalError] = useState(false);
  const memo = useCustomMemo();
  const defaultManagement = memo?.storage.defaultManagement || null;
  /**
   *
   */
  useEffect(() => {
    if (props?.products?.length > 0) setProducts(props.products);
  }, [props.products]);

  /**
   *
   */
  function getProductsSearch(search: string | null) {
    getData(`${props.searchUrl}${search}`).then((res: AxiosResponse) => {
      if (res?.data?.length > 0) setProducts(res?.data);
    });
  }

  /**
   *
   */
  function mapPropsForProduct(product: any) {
    const newProduct = Object.assign({}, new OrderLineModel());
    //check product origin (oblio or wordpress)
    newProduct.name = product?.name;
    newProduct.product_id = product?._id;
    const oblioStock = product?.oblio_stock?.find(
      (p: any) => p.management === defaultManagement?.management
    );
    newProduct.management = oblioStock?.management;
    newProduct.image =
      product?.images?.length > 0 ? product?.images[0]?.src : "";

    newProduct.price = oblioStock?.price;
    newProduct.TVA = oblioStock?.vatPercentage;
    if (product.wordPress_Id) {
      newProduct.wp_product_id = product?.wordPress_Id;
      newProduct.variation_id = product?.variations;
      newProduct.quantity = product?.quantity;
      newProduct.tax_class = product?.tax_class;
      newProduct.total = product?.total;
      newProduct.taxes = product?.tax_class;

      newProduct.sku = product?.sku;
      if (!newProduct.price) {
        newProduct.price = product?.price;
        newProduct.TVA = product?.TVA?.value;
      }
    }

    return newProduct;
  }

  return (
    <Fragment>
      <Controller
        name={`line_items[${props.index}].name`}
        control={props.control}
        defaultValue={[]}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <>
            <Autocomplete
              size="small"
              disableClearable
              id={`line_items[${props.index}].name`}
              disabled={props.disabled}
              fullWidth={true}
              className={styles.editProductOrderOffer}
              freeSolo={false}
              disablePortal
              isOptionEqualToValue={(option: any, value: any) => {
                return option?._id === value?.product_id?._id
                  ? value?.product_id?.wp_product_id
                  : value?.wp_product_id;
              }}
              getOptionLabel={(option: any) => option?.name}
              options={products}
              value={props.getValues(`line_items[${props.index}]`)}
              onChange={(
                e: any,
                product: OrderLineModel,
                reason: AutocompleteChangeReason,
                details?: any
              ) => {
                props.onProductChange(
                  e,
                  mapPropsForProduct(product),
                  reason,
                  details
                );
                setLocalError(true);
              }}
              onInputChange={(e, value, reason) => {
                if (reason === "input") {
                  if (value.length > 0) {
                    getProductsSearch(value);
                  } else {
                    getProductsSearch("");
                  }
                }
              }}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                const imageSrc = option.images[0] ? option.images[0].src : "";
                const modifiedSrc = imageSrc.replace(
                  /(\.[^\.]+)$/,
                  "-100x100$1"
                );
                return (
                  <Box
                    key={key}
                    component="li"
                    {...optionProps}
                  >
                    <img
                      width="50"
                      height={50}
                      src={modifiedSrc ? modifiedSrc : "/missingImage.png"}
                      alt=""
                    />
                    {option.name}
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={styles.editProductOrderOffer}
                  InputProps={{
                    style: {
                      fontWeight: props.isOrderOffer ? "bold" : "normal",
                    },
                    ...params.InputProps,
                    rows: 4,
                    multiline: true,
                    inputComponent: "textarea",
                  }}
                  fullWidth={true}
                  name={`line_items[${props.index}].name`}
                  error={!localError && !!error}
                  helperText={!localError && error?.message}
                  label={Vocabulary.productName}
                  variant="standard"
                />
              )}
            />
          </>
        )}
      />
    </Fragment>
  );
};
